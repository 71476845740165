import loadable from '@loadable/component';
import {
  Home,
  SeasonTicket,
  LoginWall,
  ConsentsWall,
  Register,
  SearchResults,
  Checkout,
  AddListing,
  EditListing,
  VehiclePage,
  PaymentsPage,
  WithdrawalPage,
  UpdatePrompt,
  ParkingSpaces,
  Availability,
  PartnerCheckout,
  PartnerSearchResults,
  ProfilePage,
  BookingsMadePage,
  SeasonTicketsDashboardPage,
  BookingsDetailsPage,
  Agreements,
  Logout,
  Cancellation,
  Privacy,
  Terms,
  BookingReceivedPage,
  SeasonTicketDetailsPage,
  Insurance,
  ResetPassword,
  DeleteAccount,
  PartnerHelp,
  ListingPhotos,
  InsuranceWhileParkedWebview,
  BookingsReceived,
  Dashboard,
  Cookie,
  EvSignupPage,
  EvWelcomePage,
  EvEditPayment,
  EvListingChargerDetails,
  ListingDetailsPage,
  // SuperUserPage,
  ListingOnboardingPage,
  RentOutYourSpacePage,
  CommunicationSettings,
  FastTrackPolicy,
  LoungesPolicy,
  MessagingPage,
  BillingsAndWithdrawalsPage,
  Promo,
  McdonaldsPolicy,
  MessageDetailsPage,
  AirportSearch
} from './pages/Pages';
import withAuth from './dataLoaders/withAuth';
import withFeatureRoute from './dataLoaders/withFeatureRoute';

const consentWallLoader = async (...args) => {
  const loader = await import('./dataLoaders/consent');
  return loader.default(...args);
};

export default () => [
  {
    element: Home,
    path: '/',
    handle: {
      hideFindParking: true
    },
    loader: async (...args) => {
      const loader = await import('./dataLoaders/home');
      return loader.default(...args);
    }
  },
  {
    element: Dashboard,
    path: '/dashboard'
  },
  {
    element: ConsentsWall,
    loader: consentWallLoader,
    path: '/dashboard/profile/data-preferences/',
    strict: false
  },
  {
    element: loadable(
      () => import(/* webpackChunkName: "page-landing" */ './pages/Landing')
    ),
    loader: async (...args) => {
      const loader = await import('./dataLoaders/landing');
      return loader.default(...args);
    },
    path: '/:country/parking/:slug/*',
    strict: true,
    handle: {
      hideFindParking: true
    }
  },
  {
    element: LoginWall,
    path: '/auth/login',
    strict: false
  },
  {
    element: ConsentsWall,
    loader: consentWallLoader,
    path: '/consent-wall',
    strict: false
  },
  {
    element: Register,
    path: '/registration',
    strict: false
  },
  {
    element: SearchResults,
    loader: async (...args) => {
      // NOTE: This route has shouldRevalidate: false
      // If the loader below is changed and is dependent on the search query shouldRevalidate may need to be updated
      // Some query params may never need revalidation, such as the listingId param
      const loader = await import('./dataLoaders/searchResultsLoader');
      return loader.default(...args);
    },
    path: '/search/',
    handle: {
      routeType: 'search',
      hideFooter: true
    },
    shouldRevalidate: false
  },
  {
    element: PartnerSearchResults,
    loader: async (...args) => {
      const loader = await import('./dataLoaders/searchResultsPartnerLoader');
      return loader.default(...args);
    },
    path: '/partner/:partnerName/search/',
    exact: true,
    handle: {
      routeType: 'search',
      hideFooter: true
    }
  },
  {
    element: Checkout,
    loader: async (...args) => {
      const loader = await import('./dataLoaders/checkoutLoader');
      return loader.default(...args);
    },
    path: '/checkout/:listingId',
    handle: {
      routeType: 'checkout',
      hideFooter: true
    }
  },
  {
    element: PartnerCheckout,
    loader: async (...args) => {
      const loader = await import('./dataLoaders/partnerCheckoutLoader');
      return loader.default(...args);
    },
    path: '/partner/:partnerName/checkout/:listingId',
    handle: {
      routeType: 'checkout',
      hideFooter: true
    }
  },
  {
    element: SeasonTicket,
    loader: async (...args) => {
      const loader = await import('./dataLoaders/seasonTicketLoader');
      return loader.default(...args);
    },
    path: '/season-tickets/:vendor',
    exact: true
  },
  {
    element: AddListing,
    path: '/dashboard/listings/add',
    handle: {
      headerHidePrimaryNav: true,
      headerMinimalBackground: true,
      headerDarkMobileMenu: true,
      hideFooter: true
    }
  },
  {
    element: ParkingSpaces,
    path: '/dashboard/parking-spaces',
    exact: true,
    handle: {
      headerMinimalBackground: true
    }
  },
  {
    element: EditListing,
    path: '/dashboard/listings/edit/:listingId',
    exact: true,
    handle: {
      headerHidePrimaryNav: true,
      headerMinimalBackground: true,
      headerDarkMobileMenu: true,
      hideFooter: true
    },
    loader: async (...args) => {
      const loader = await import('./dataLoaders/editListingLoader');
      return loader.default(...args);
    }
  },
  {
    element: UpdatePrompt,
    path: '/dashboard/listings/check/:id',
    exact: true,
    handle: {
      headerHidePrimaryNav: true,
      hideFooter: true,
      headerDarkMobileMenu: true
    }
  },
  {
    element: Availability,
    path: '/dashboard/parking-spaces/:listingId/availability/',
    state: {
      page: 'home'
    },
    handle: {
      headerHidePrimaryNav: true,
      headerMinimalBackground: true
    }
  },
  {
    element: ListingPhotos,
    path: '/dashboard/listings/edit/:listingId/photos/',
    loader: async (args) => {
      const loader = await import('./dataLoaders/listingPhotos');
      return withAuth(loader.default)(args);
    },
    handle: {
      headerMinimalBackground: true
    }
  },
  {
    element: VehiclePage,
    path: '/vehicles'
  },
  {
    element: PaymentsPage,
    path: '/dashboard/billing/payment-sources'
  },
  {
    element: WithdrawalPage,
    path: '/dashboard/billing/withdrawal',
    exact: true
  },
  {
    element: ProfilePage,
    path: '/dashboard/profile',
    exact: true,
    loader: async (args) => {
      const loader = await import('./dataLoaders/profile');
      return withAuth(loader.default)(args);
    },
    children: [
      {
        element: ProfilePage,
        path: '/dashboard/profile/email_verified=1'
      },
      {
        element: ProfilePage,
        path: '/dashboard/profile/phone_verified=1'
      }
    ]
  },
  {
    element: BookingsMadePage,
    path: '/dashboard/bookings/',
    exact: true,
    loader: async (...args) => {
      const loader = await import('./dataLoaders/bookingsMade');
      return loader.default(...args);
    },
    children: [
      {
        element: BookingsMadePage,
        path: '/dashboard/bookings/made/'
      }
    ]
  },
  {
    element: InsuranceWhileParkedWebview,
    loader: async (...args) => {
      const loader = await import(
        './dataLoaders/insuranceWhileParkedWebviewLoader'
      );
      return loader.default(...args);
    },
    path: '/dashboard/bookings/made/:bookingId/insurance',
    exact: true,
    handle: {
      hideFooter: true
    }
  },
  {
    element: BookingsDetailsPage,
    loader: async (...args) => {
      const loader = await import('./dataLoaders/bookingDetailsLoader');
      return loader.default(...args);
    },
    path: '/dashboard/bookings/made/:bookingId',
    children: [
      {
        element: BookingsDetailsPage,
        path: '/dashboard/bookings/made/:bookingId/vehicle/change'
      },
      {
        element: BookingsDetailsPage,
        path: '/dashboard/bookings/made/:bookingId/driver-amendment'
      },
      {
        element: BookingsDetailsPage,
        path: '/dashboard/bookings/made/:bookingId/driver-cancellation'
      }
    ]
  },
  {
    element: BookingsDetailsPage,
    loader: async (...args) => {
      const loader = await import('./dataLoaders/bookingDetailsLoader');
      return loader.default(...args);
    },
    path: '/dashboard/feedback/leave-feedback/:bookingId'
  },
  {
    element: SeasonTicketsDashboardPage,
    path: '/dashboard/season-tickets/',
    exact: true
  },
  {
    element: SeasonTicketDetailsPage,
    path: '/dashboard/season-tickets/:id',
    loader: async (...args) => {
      const loader = await import('./dataLoaders/seasonTicketDetails');
      return loader.default(...args);
    }
  },
  {
    element: Promo,
    loader: async (...args) => {
      const loader = await import('./dataLoaders/promo');
      return loader.default(...args);
    },
    path: '/promo/:slug'
  },
  {
    element: Logout,
    path: '/auth/logout',
    loader: async (...args) => {
      const loader = await import('./dataLoaders/logout');
      return loader.default(...args);
    },
    exact: true
  },
  {
    element: Agreements,
    path: '/driver-agreement',
    exact: true
  },
  {
    element: Agreements,
    path: '/owner-agreement',
    exact: true
  },
  {
    element: Cancellation,
    path: '/cancellation-policy',
    exact: true
  },
  {
    element: Privacy,
    path: '/privacy-policy',
    exact: true
  },
  {
    element: Cookie,
    path: '/cookie-policy',
    exact: true
  },
  {
    element: Terms,
    path: '/terms-and-conditions',
    exact: true
  },
  {
    element: Insurance,
    path: '/insurance-policy',
    exact: true
  },
  {
    element: FastTrackPolicy,
    path: '/fast-track-policy',
    exact: true
  },
  {
    element: LoungesPolicy,
    path: '/airport-lounge-policy',
    exact: true
  },
  {
    element: McdonaldsPolicy,
    loader: async (...args) => {
      withFeatureRoute('mcdonalds-tcs', ...args);
      return null;
    },
    path: '/mcdonalds-policy',
    exact: true
  },
  {
    element: BookingsReceived,
    path: '/dashboard/bookings/received',
    loader: async (...args) => {
      const loader = await import('./dataLoaders/bookingsReceived');
      return loader.default(...args);
    },
    exact: true
  },
  {
    element: BookingReceivedPage,
    path: '/dashboard/bookings/received/:bookingId',
    exact: true,
    loader: async (args) => {
      const loader = await import('./dataLoaders/bookingReceived');
      return withAuth(loader.default)(args);
    }
  },
  {
    element: ResetPassword,
    path: '/password/reset/update',
    exact: true
  },
  {
    element: DeleteAccount,
    path: '/auth/consents/revoke',
    exact: true,
    loader: async (args) => {
      const loader = await import('./dataLoaders/deleteAccount');
      return withAuth(loader.default)(args);
    }
  },
  {
    element: PartnerHelp,
    loader: async (...args) => {
      const loader = await import('./dataLoaders/help');
      return loader.default(...args);
    },
    path: '/help',
    handle: {
      headerHidePrimaryNav: true,
      headerMinimalBackground: true
    }
  },
  {
    element: EvSignupPage,
    path: '/users/ev-signup',
    exact: true,
    loader: async (...args) => {
      const loader = await import('./dataLoaders/evSignUp');
      return loader.default(...args);
    }
  },
  {
    element: EvWelcomePage,
    path: '/users/ev-welcome',
    exact: true
  },
  {
    element: EvEditPayment,
    path: '/users/edit-ev-payment',
    exact: true
  },
  {
    element: EvListingChargerDetails,
    path: '/dashboard/listings/edit/:listingId/ev/',
    exact: true,
    handle: {
      headerHidePrimaryNav: true,
      headerMinimalBackground: true,
      headerDarkMobileMenu: true
    }
  },
  {
    element: ListingDetailsPage,
    loader: async (...args) => {
      const loader = await import('./dataLoaders/listingDetailsPageLoader');
      return loader.default(...args);
    },
    path: '/:country/parking/:citySlug/s/:slug',
    strict: true,
    handle: {
      hideFindParking: true
    }
  },
  {
    element: BookingReceivedPage,
    path: '/dashboard/bookings/:bookingId/owner-cancellation/'
  },
  // {
  //   element: SuperUserPage,
  //   path: '/admin/su',
  //   strict: true
  // },
  {
    element: ListingOnboardingPage,
    loader: async (...args) => {
      const loader = await import('./dataLoaders/sOOnboardingLoader');
      return loader.default(...args);
    },
    path: '/dashboard/listing-onboarding',
    exact: true,
    handle: {
      hideHeader: true,
      hideFooter: true
    }
  },
  {
    element: RentOutYourSpacePage,
    path: '/how-it-works/rent-out-your-driveway',
    exact: true,
    handle: {
      hideFindParking: true
    }
  },
  {
    element: CommunicationSettings,
    path: '/dashboard/profile/communication-settings',
    action: async (...args) => {
      const action = await import('./actions/communicationSettings');
      return action.default(...args);
    },
    loader: async (...args) => {
      withFeatureRoute('communication-settings', ...args);

      const loader = await import('./dataLoaders/communicationSettings');
      return withAuth(loader.default)(...args);
    },
    exact: true
  },
  {
    element: MessagingPage,
    path: '/dashboard/messaging',
    loader: async (...args) => {
      withFeatureRoute('messages', ...args);
      const loader = await import('./dataLoaders/messages');
      return withAuth(loader.default)(...args);
    },
    exact: true
  },
  {
    element: MessageDetailsPage,
    path: '/dashboard/messaging/:messageId',
    action: async (...args) => {
      const action = await import('./actions/messages');
      return action.default(...args);
    },
    loader: async (...args) => {
      withFeatureRoute('messages', ...args);
      const loader = await import('./dataLoaders/messagesDetails');
      return withAuth(loader.default)(...args);
    },
    exact: true
  },
  {
    element: BillingsAndWithdrawalsPage,
    path: '/dashboard/billing',
    loader: async (...args) => {
      withFeatureRoute('billings-and-withdrawals', ...args);
      const loader = await import('./dataLoaders/billingsAndWithdrawals');
      return withAuth(loader.default)(...args);
    },
    exact: true
  },
  {
    element: AirportSearch,
    path: '/airport-parking',
    exact: true,
    handle: {
      routeType: 'search',
      hideFooter: true
    },
    loader: async (...args) => {
      const loader = await import('./dataLoaders/airportSearchLoader');
      return loader.default(...args);
    }
  },
  {
    path: '*',
    loader: async (...args) => {
      const loader = await import('./dataLoaders/notFound');
      return loader.default(...args);
    }
  }
];
